<template>
  <div v-if="store.contentData" class="overallpage">
    <h2>{{getContentData('apply').headline}}</h2>
    <span v-html="getContentData('apply').pageContent"></span>

    <h2>Du willst mehr erfahren?</h2>    
    <div class="button shadow-box" v-on:click="openPage('collectors')">
      Zu den Ortsgruppen
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Apply',
  metaInfo: {
    title: 'Second Bandshirt - Apply',
    htmlAttrs: {
      lang: 'de',
      amp: true
    }
  },
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    getContentData: function (dataHandle) {
      var contentModel = null;
      for(var i = 0; i < this.store.contentData.length; i++){
        if(this.store.contentData[i].pageHandle == dataHandle){
          contentModel = this.store.contentData[i];
        }
      }
      return contentModel;
    },
    openPage(url){
      this.$router.push(url);
    },
  }
}
</script>

<style lang="scss">
  a{
    padding-left: 0!important;
  }
</style>
